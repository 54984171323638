body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  background-color: #f6f5f1;
  color: #1c2445;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

@font-face {
  font-family: 'Texgyre Heros CN';
  src: url('./fonts/texgyre-heros-cn-regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Texgyre Heros';
  src: url('./fonts/texgyre-heros-regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Texgyre Heros';
  src: url('./fonts/texgyre-heros-italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Texgyre Heros CN';
  src: url('./fonts/texgyre-heros-cn-bolditalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Texgyre Heros CN';
  src: url('./fonts/texgyre-heros-cn-bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Texgyre Heros';
  src: url('./fonts/texgyre-heros-bolditalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Texgyre Heros CN';
  src: url('./fonts/texgyre-heros-cn-italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Texgyre Heros';
  src: url('./fonts/texgyre-heros-bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}